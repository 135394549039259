@import "../../scss/vars.scss";

.tablecard {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px 0;
  height: 115px;
  overflow: hidden;
  transition: all 0.3s linear;
  position: relative;
  &.active {
    overflow: visible;
    height: 450px;
  }
}
