@import "../../scss/vars.scss";

.signIn {
  background-color: $backgroundBlue;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s linear;
  &__form {
    min-height: 695px;
    min-width: 516px;
    background-color: $backgroundWhiteWithOutOpacity;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      padding-top: 40px;
      padding-bottom: 20px;
    }
  }
  &__title {
    font-family: $poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: $blackText;
    padding-bottom: 40px;
  }
  &__form__wrapper {
    width: 316px;
    height: 200px;
  }
}
