@import "../../scss/vars.scss";

.modal {
  height: 100vh;
  width: 100vw;
  position: fixed;
  width: 28.125em;
  height: 150px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  z-index: 100;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 23px 50px 5px rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s linear;
  &.active {
    opacity: 1;
    visibility: visible;
  }
  &__text {
    font-family: $inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #11142d;
  }
  &__btnWrapper {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  &__cancel,
  &__confirm {
    color: white;
    padding: 8px 10px;
    border-radius: 10px;
    cursor: pointer;
  }
  &__cancel {
    background-color: $primary;
  }
  &__confirm {
    background-color: $red;
  }
}
