//colors
$white: #fff;
$black: #000;
$primary: #4f7a9f;
$liteGrey: #e4e4e4;
$red: #eb5757;
$disable: #808191;
//Text
$blackText: #11142d;
$whiteText: #ffffff;
$disableText: #808191;
//Backgrounds
$backgroundWhite: rgba(255, 255, 255, 0.9);
$backgroundWhiteWithOutOpacity: #ffffff;
$backgroundBlue: #4f7a9f;
$backgroundLiteGrey: #e4e4e4;
$backgroundRed: #eb5757;

//fonts
$inter: "Inter", sans-serif;
$poppins: "Poppins", sans-serif;
