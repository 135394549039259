@import "../../scss/vars.scss";

.form {
  width: 100%;
  .input__wrapper {
    display: flex;
    flex-direction: column;
  }
  &__label {
    width: 100%;
    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $disableText;
    margin-bottom: 16px;
  }
  &__input {
    width: 100%;
    padding: 17px 0 17px 23px;
    background-color: $backgroundLiteGrey;
    border: none;
    border-radius: 10px;
    border: 2px solid $backgroundLiteGrey;
    transition: all 0.3s linear;
    &:focus {
      background-color: $white;
      border: 2px solid $primary;
    }
  }
  &__button {
    width: 100%;
    background-color: $backgroundRed;
    color: $whiteText;
    border: none;
    border-radius: 10px;
    padding: 18px 0;
    margin-top: 56px;
    cursor: pointer;
    &:hover {
      background-color: #d92b2b;
    }
  }
  &__error {
    font-family: $inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $red;
    margin-bottom: 16px;
  }
}
