@import "../../scss/vars.scss";

.asideMenu {
  height: 100vh;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  &__logo {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 48px;
    margin-bottom: 35px;
    img {
      width: 100%;
    }
  }
  &__buttons {
    width: 100%;
    .text {
      font-family: $inter;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $disableText;
      padding-bottom: 16px;
      margin-left: 40px;
    }
    ul .btn {
      margin-left: 40px;
      margin-right: 40px;
      &__content {
        display: flex;
        align-items: center;
        .icon {
          padding: 15px;
          svg {
            opacity: 0.4;
          }
        }
        .btnText {
          color: $disableText;
          font-family: $inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }
      a.active {
        .btn__content {
          background-color: $backgroundBlue;
          border-radius: 10px;
          svg {
            opacity: 1;
            path,
            circle {
              stroke: $whiteText;
            }
          }
          .btnText {
            color: $whiteText;
          }
        }
      }
    }
  }
  &__info {
    margin-left: 40px;
    margin-bottom: 50px;
    margin-right: 40px;
    .emailText {
      font-family: $inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $blackText;
    }
    .text {
      font-family: $inter;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: $disableText;
    }
    .logOut__btn {
      background-color: $backgroundRed;
      font-family: $inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      padding: 8px 18px;
      border-radius: 10px;
      cursor: pointer;
      margin-top: 10px;
      position: relative;
    }
  }
}
